import React from 'react';
import _ from 'lodash';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider } from '@mui/material/styles';
import {
	ClientConfigProvider,
	CableNotificationsProvider,
	ActionCableProvider,
	ApolloCableProvider,
	SnackbarProvider,
	SettingsProvider,
} from '../../context';
import { restoreSettings } from '../../shared/libs/settings';
import { sendError } from '../../shared/libs/logger';
import { createCableConsumer } from '../setup/cable';
import ErrorBoundaryFallback from '../../core/components/ErrorBoundaryFallback';
import createTheme from '../setup/theme';
import { defaultLocale, dynamicActivate } from '../setup/i18n';
import { getClientConfig } from '../lib/appsRegistry';

const settings = restoreSettings();

export default (createStore, createApolloClient, AppContent) =>
	async (serverData) => {
		const { props } = serverData;
		const domain = _.get(props, ['company', 'domain']);
		const clientConfig = await getClientConfig({ domain });
		const apolloClient = createApolloClient(clientConfig, props);
		const cableConnection = createCableConsumer(props);
		const store = createStore({
			clientConfig,
			cableConnection,
			apolloClient,
			...props,
		});

		const ThemeProvider = createTheme({ clientConfig });
		const combinedSettings = { ...props, ...settings };
		const locale = _.get(store.getState(), 'app.i18n.locale', defaultLocale);
		await dynamicActivate(locale);

		const { railsEnv } = props;

		if (railsEnv === 'development') {
			ReactGA.initialize('GTM-PSF4D39');
		}

		const errorHandler = (error, componentStack) => {
			const { serverUrl } = props;
			sendError({
				serverUrl,
				error,
				componentStack,
				state: store.getState(),
			});
		};

		return (
			<ApolloProvider client={apolloClient}>
				<Provider store={store}>
					<ClientConfigProvider clientConfig={clientConfig}>
						<SettingsProvider settings={combinedSettings}>
							<StyledEngineProvider injectFirst>
								<ThemeProvider>
									<I18nProvider i18n={i18n}>
										<ErrorBoundary
											FallbackComponent={ErrorBoundaryFallback}
											onError={errorHandler}
										>
											<ActionCableProvider consumer={cableConnection}>
												<ApolloCableProvider
													channels={[
														'Account::NotificationsChannel',
														'Account::UserChannel',
													]}
												>
													<CableNotificationsProvider>
														<SnackbarProvider>
															<AppContent store={store} />
														</SnackbarProvider>
													</CableNotificationsProvider>
												</ApolloCableProvider>
											</ActionCableProvider>
										</ErrorBoundary>
									</I18nProvider>
								</ThemeProvider>
							</StyledEngineProvider>
						</SettingsProvider>
					</ClientConfigProvider>
				</Provider>
			</ApolloProvider>
		);
	};
