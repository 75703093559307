import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const RootDiv = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	display: 'flex',
	height: '100%',
	overflow: 'hidden',
	width: '100%',
}));

const WrapperDiv = styled('div')(() => ({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden',
}));

const ContainerDiv = styled('div')(() => ({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden',
}));

const ContentDiv = styled('div')(() => ({
	flex: '1 1 auto',
	height: '100%',
	overflow: 'auto',
}));

const Minimal = ({ children, className }) => (
	<RootDiv className={className}>
		<WrapperDiv>
			<ContainerDiv>
				<ContentDiv>{children}</ContentDiv>
			</ContainerDiv>
		</WrapperDiv>
	</RootDiv>
);

Minimal.defaultProps = {
	className: () => null,
};

Minimal.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default Minimal;
