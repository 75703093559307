import React from 'react';
import PropTypes from 'prop-types';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { LicenseInfo as PickersLicense } from '@mui/x-license-pro';
import _ from 'lodash';
import { create } from 'jss';
import { responsiveFontSizes } from '@mui/material';
import {
	createTheme,
	ThemeProvider as MuiThemeProvider,
	StyledEngineProvider,
} from '@mui/material/styles';
import { jssPreset, StylesProvider } from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import typography from './themeConf/typography';
import defaultThemeConfigs from './themeConf/index';
import { getCompanyTheme } from '../../context/ClientConfigContext';
import useSettings from '../../hooks/useSettings';

const baseConfig = {
	direction: 'ltr',
	typography,
	overrides: {
		MuiLinearProgress: {
			root: {
				borderRadius: 3,
				overflow: 'hidden',
			},
		},
		MuiListItemIcon: {
			root: {
				minWidth: 32,
			},
		},
		MuiChip: {
			root: {
				backgroundColor: 'rgba(0,0,0,0.075)',
			},
		},
	},
};

const LICENSE_KEY =
	'25cb374b6ad6844ceccc9d33fd5d94d7Tz04MTk1MixFPTE3MzY1MzE3MjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

const createThemeProvider = ({ clientConfig } = {}) => {
	LicenseInfo.setLicenseKey(LICENSE_KEY);
	PickersLicense.setLicenseKey(LICENSE_KEY);

	const jss = create({ plugins: [...jssPreset().plugins] });
	const themeData = getCompanyTheme(clientConfig);

	const { themeConfigs: compThemeConfigs, themeOverrides: compThemeOverrides } =
		themeData;

	const themeConfigs = [...defaultThemeConfigs, ...compThemeConfigs];

	const createThemeFunc = (settings = {}) => {
		let themeConfig = themeConfigs.find(
			(newTheme) => newTheme.name === settings.theme
		);

		if (!themeConfig) {
			console.warn(new Error(`The theme ${settings.theme} is not valid`));
			[themeConfig] = themeConfigs;
		}

		let overrides = {};
		if (compThemeOverrides) {
			overrides = compThemeOverrides.find(
				(newTheme) => newTheme.name === settings.theme
			);
		}
		overrides = overrides || {};

		let theme = createTheme(
			_.merge({}, baseConfig, themeConfig, overrides, {
				direction: settings.direction,
			})
		);

		if (settings.responsiveFontSizes) {
			theme = responsiveFontSizes(theme);
		}

		return theme;
	};

	return (props) => {
		const { settings } = useSettings();
		const { children } = props;

		return (
			<StyledEngineProvider injectFirst>
				<MuiThemeProvider theme={createThemeFunc(settings)}>
					<StylesProvider jss={jss}>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							{children}
						</LocalizationProvider>
					</StylesProvider>
				</MuiThemeProvider>
			</StyledEngineProvider>
		);
	};
};

createThemeProvider.defaultProps = {
	clientConfig: null,
};

createThemeProvider.propTypes = {
	clientConfig: PropTypes.objectOf(PropTypes.any),
};

export default createThemeProvider;
